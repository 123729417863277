import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";

import Image from "./Image";

const OhranitelniRoletki = ({ frontmatter }) => {
  return (
    <div className="container d-flex flex-wrap pt-5 pt-lg-6">
      <h3 className="col-12 pb-2">{frontmatter.header}</h3>
      <div className="d-flex justify-content-lg-start justify-content-center flex-column col-12 px-md-0 px-2 col-lg-6 px-0">
        <Carousel
          variant="dark"
          className="col-md-8 col-lg-12 align-self-center flex-md-shrink-1"
          indicators={false}
          nextIcon={<FontAwesomeIcon icon={faChevronRight} size="lg" color="#212529" />}
          prevIcon={<FontAwesomeIcon icon={faChevronLeft} size="lg" color="#212529" />}
        >
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="ohranitelni_roletki/1.jpg"
              alt="Охранителна ролетка тип оплетка"
              style={{ maxHeight: "300px" }}
            />
            <Carousel.Caption className="text-dark">Тип оплетка</Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="ohranitelni_roletki/2.jpg"
              alt="Плътна охранителна щора"
              style={{ maxHeight: "300px" }}
            />
            <Carousel.Caption className="text-dark">Плътна охранителна щора</Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <div className="row justify-content-center my-3">
          <Link
            className="btn btn-primary"
            to="/gallery/#ohranitelni_roletki"
            activeClassName="active"
          >
            Към Галерията
          </Link>
        </div>
      </div>
      <div className="col-lg-6">
        Всички видове охранителни ролетки се предлагат с:
        <ul>
          <li>Елегантна касета, в която компактно се прибират и не заемат много пространство.</li>
          <li>Гумени уплътнения и четка за безшумна работа.</li>
          <li>Ръчно повдигане чрез манивела или чрез мотор.</li>
          <li>Възможност за дистанционно управление или чрез бутон.</li>
          <li>Богата гама на цветове.</li>
        </ul>
        Предлагаме следните два вида охранителни ролетки:
      </div>
      <div className="col-12 mt-4 mt-lg-0">
        <div className="mb-2">
          <strong>Охранителни ролетки с плътни ламели</strong>
        </div>
        <div>
          Подходящи за гаражи, халета и всякакви помещения, в които не е необходима светлина при
          спусната решетка. Тяхно предимство е, че спират въздушното течение. Възможно е и
          изработката им от термоламели от стомана или алуминий, в случаи на необходимост от
          топлоизолация на помещението.
        </div>
      </div>
      <div className="col-12 mt-4">
        <div className="mb-2">
          <strong>Охранителни ролетки тип Оплетка</strong>
        </div>
        <div>
          Предоставят комбинация от здравина, сигурност и добра видимост към вътрешността на обекта.
          Произвеждат се от подцинковани стоманени пръти захванати със стоманени скоби.
        </div>
      </div>
    </div>
  );
};

OhranitelniRoletki.propTypes = {
  frontmatter: PropTypes.object,
};

OhranitelniRoletki.defaultProps = {
  frontmatter: {},
};

export default OhranitelniRoletki;
